import React from 'react';

const UnpaidInvoiceSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="400"
      height="250"
      preserveAspectRatio="none"
      viewBox="0 0 400 250">
      <g mask='url("#SvgjsMask1530")' fill="none">
        <path
          d="M209 112L130 191"
          strokeWidth="10"
          stroke="url(#SvgjsLinearGradient1531)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M324 10L149 185"
          strokeWidth="8"
          stroke="url(#SvgjsLinearGradient1532)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M333 35L508 -140"
          strokeWidth="10"
          stroke="url(#SvgjsLinearGradient1532)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M282 58L131 209"
          strokeWidth="10"
          stroke="url(#SvgjsLinearGradient1531)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M290 16L410 -104"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1532)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M216 186L328 74"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1531)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M255 53L176 132"
          strokeWidth="10"
          stroke="url(#SvgjsLinearGradient1531)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M339 191L519 11"
          strokeWidth="8"
          stroke="url(#SvgjsLinearGradient1531)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M95 151L185 61"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1532)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M249 16L342 -77"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1532)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M129 230L286 73"
          strokeWidth="10"
          stroke="url(#SvgjsLinearGradient1531)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M80 216L3 293"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1531)"
          strokeLinecap="round"
          className="BottomLeft"></path>
      </g>
      <defs>
        <mask id="SvgjsMask1530">
          <rect width="400" height="250" fill="#ffffff"></rect>
        </mask>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="SvgjsLinearGradient1531">
          <stop stopColor="rgba(var(--tb-warning-rgb), 0)" offset="0"></stop>
          <stop stopColor="rgba(var(--tb-warning-rgb), 0.1)" offset="1"></stop>
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="100%"
          x2="100%"
          y2="0%"
          id="SvgjsLinearGradient1532">
          <stop stopColor="rgba(var(--tb-warning-rgb), 0)" offset="0"></stop>
          <stop stopColor="rgba(var(--tb-warning-rgb), 0.1)" offset="1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default UnpaidInvoiceSvg;
