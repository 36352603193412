import BreadCrumb from 'Common/BreadCrumb';
import React from 'react';
import {Container, Row} from 'react-bootstrap';

// import Components
import ProductFilter from 'ProductListing/ProductFilter';
import {DOCUMENT_TITLE} from 'Common/constants/layout';

const ProductListing = (): JSX.Element => {
  document.title = DOCUMENT_TITLE.PRODUCT_LIST;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Product List" pageTitle="Products" />
          <Row>
            <ProductFilter />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductListing;
