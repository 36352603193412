import ModalContainer from 'Common/ModalContainer';
import React from 'react';
import {Col} from 'react-bootstrap';
interface FullImagePreviewerProps {
  toggleImagePreview: () => void;
  showModalImg: boolean;
  fullImageUrl: string;
}
const FullImagePreviewer: React.FC<FullImagePreviewerProps> = ({
  toggleImagePreview,
  showModalImg,
  fullImageUrl,
}) => {
  const modalData = (): JSX.Element => {
    return (
      <Col lg={12}>
        <img src={fullImageUrl} width="100%" height="auto" />
      </Col>
    );
  };
  return (
    <div>
      <ModalContainer
        showModal={showModalImg}
        handleClose={toggleImagePreview}
        modalBody={modalData()}
      />
    </div>
  );
};

export default FullImagePreviewer;
