/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {useEffect, useState} from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';

import OrderDetailsCard from './OrderDetailsCard';
import ModalContainer from 'Common/ModalContainer';
import OrderCancel from './OrderCancel';
import ApiUtils from 'api/ApiUtils';
import BreadCrumb from 'Common/BreadCrumb';
import {ORDER_STATUS} from 'Common/constants/layout';

interface OrderDetailItemType {
  product_id: string;
  product_name: string;
  total_amount: number;
  qty: number;
  length: number;
  product_option_value: any;
  gst_tax_value: number;
  price: number;
  tax_amount: number;
  gift_from: string;
  gift_message: string;
  is_gift: any;
}
interface PaymentDetailItem {
  id: string;
  fee: number;
  tax: number;
  vpa: string | null;
  // eslint-disable-next-line @typescript-eslint/ban-types
  bank: {} | null;
  card: {
    id: string;
    emi: boolean;
    name: string;
    type: string;
    last4: string;
    entity: string;
    issuer: string | null;
    network: string;
    sub_type: string;
    token_iin: string | null;
    international: boolean;
  };
  email: string;
  notes: {
    address: string;
  };
  amount: number;
  entity: string;
  method: string;
  status: string;
  wallet: string | null;
  card_id: string;
  contact: string;
  captured: boolean;
  currency: string;
  order_id: string;
  created_at: number;
  error_code: string | null;
  error_step: string | null;
  invoice_id: string | null;
  description: string;
  error_reason: string | null;
  error_source: string | null;
  acquirer_data: {
    auth_code: string;
  };
  international: boolean;
  refund_status: string | null;
  amount_refunded: number;
  error_description: string | null;
}
interface OrderDetailType {
  created_at: string;
  customer_address: {
    customer_first_name: string;
    customer_last_name: string;
    phone_number: string;
    address_line_1: string;
    address_line_2: string;
    city_name: string;
    postcode: string;
  };
  customer_address_id: number;
  customer_id: number;
  id: number;
  order_detail: OrderDetailItemType[];
  order_status: number | undefined;
  payment_type: number;
  payment_detail: PaymentDetailItem | string | unknown;
  total_amount: number;
  tax_amount: number;
  final_total_amount: number;
  coupon_amount: number;
  coupon_name: string;
}
const OrderDetails = (): JSX.Element => {
  const {orderId} = useParams();
  const navigate = useNavigate();
  document.title = 'Order Overview | Admin eCommerce';
  const [modalFlag, setModalFlag] = useState(false);
  const [modalFlag1, setModalFlag1] = useState(false);

  const [orderDetail, setOrderDetail] = useState<OrderDetailType | null>(null);

  // Check if payment_detail is a string and needs parsing
  const payDetail =
    typeof orderDetail?.payment_detail === 'string'
      ? JSON.parse(orderDetail?.payment_detail)
      : typeof orderDetail?.payment_detail === 'object'
        ? orderDetail?.payment_detail // If it's already an object, use it as is
        : null; // Handle other cases if necessary

  function modalToggle(): void {
    setModalFlag(!modalFlag);
  }
  useEffect(() => {
    if (orderId != null) {
      void getUsersList();
    } else {
      navigate('/order-list');
    }
  }, []);
  async function getUsersList(): Promise<void> {
    try {
      const res: any = await ApiUtils.getOrderList(`?id=${orderId}`);
      setOrderDetail(res?.data as OrderDetailType);
    } catch (error: any) {
      // toast.error(error.message);
    }
  }
  const openGiftDetailsModal = (order: OrderDetailItemType): void => {
    // Update state or take any necessary action to open the modal
    // You can use the `setModalFlag` function or any other mechanism you have in place
    setModalFlag1(true);

    // You can also set additional modal-specific state to pass 'gift_from' and 'gift_message'
    // e.g., setGiftDetails({ giftFrom: order.gift_from, giftMessage: order.gift_message });
  };
  return (
    <div className="page-content">
      <Container fluid={true}>
        <BreadCrumb
          title="Order Details"
          pageTitle="Order List"
          pageLink="/order-list"
        />
        <Row className="mb-4 align-items-center">
          <Col>
            <h6 className="fs-18 mb-0">Order ID: #{orderDetail?.id}</h6>
          </Col>
        </Row>

        <Row>
          <Col xxl={8}>
            <Row>
              <OrderDetailsCard orderDetail={orderDetail?.customer_address} />
            </Row>
            <Col xxl={12}>
              <Card>
                <div className="card-body">
                  <div className="table-responsive table-card">
                    <table className="table table-bordered align-middle table-nowrap mb-0">
                      <thead className="text-muted table-light">
                        <tr>
                          <th role="columnheader">Product ID</th>
                          <th role="columnheader">Product Name</th>
                          <th role="columnheader">Price</th>
                          <th role="columnheader">Quantity</th>
                          <th role="columnheader">Tax (%)</th>
                          <th role="columnheader">Tax Value</th>
                          <th role="columnheader">Gift</th>

                          <th
                            role="columnheader"
                            className="text-center"
                            colSpan={3}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetail?.order_detail != null &&
                        orderDetail?.order_detail.length > 0
                          ? orderDetail?.order_detail?.map(
                              (order: OrderDetailItemType, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="scrollable-cell" role="cell">
                                      <Link
                                        to={`https://ecom-user.tecmantras.com/product-detail/${order?.product_id}`}
                                        className="fw-medium link-primary">
                                        #{order?.product_id}
                                      </Link>
                                    </td>
                                    <td className="scrollable-cell" role="cell">
                                      {order?.product_name}
                                    </td>
                                    <td className="scrollable-cell" role="cell">
                                      {order?.price}
                                    </td>
                                    <td className="scrollable-cell" role="cell">
                                      {order?.qty}
                                    </td>
                                    <td className="scrollable-cell" role="cell">
                                      {order?.gst_tax_value}%
                                    </td>
                                    <td className="scrollable-cell" role="cell">
                                      {order?.tax_amount}
                                    </td>
                                    <td className="scrollable-cell" role="cell">
                                      {order?.is_gift !== null ? (
                                        <button
                                          className="btn btn-link"
                                          onClick={() => {
                                            openGiftDetailsModal(order);
                                          }}>
                                          Yes
                                        </button>
                                      ) : (
                                        'No'
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {order?.total_amount}
                                    </td>
                                  </tr>
                                );
                              },
                            )
                          : ''}

                        <tr>
                          <td colSpan={4}></td>
                          <td colSpan={5} className="p-0">
                            <table className="table table-borderless mb-0">
                              <tbody>
                                <tr>
                                  <td>Tax Value (Rs) :</td>
                                  <td className="text-end">
                                    {orderDetail?.tax_amount ?? 0}
                                  </td>
                                </tr>
                                {orderDetail?.coupon_amount ? (
                                  <tr>
                                    <td>
                                      Discount ({orderDetail?.coupon_name}):
                                    </td>
                                    <td className="text-end">
                                      {orderDetail?.coupon_amount}
                                    </td>
                                  </tr>
                                ) : (
                                  ''
                                )}
                                <tr className="border-top">
                                  <th>Total (Rs) :</th>
                                  <th className="text-end">
                                    {orderDetail?.final_total_amount}
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </Col>
          </Col>
          <Col xxl={4}>
            <Row>
              <Col lg={12}>
                <Card>
                  <div className="card-header d-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0">
                      Logistics Details
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <i className="bi bi-truck fs-1"></i>
                      <h5 className="fs-18">Ecom Logistics</h5>
                      <p className="mb-0">
                        <strong>ID: </strong>MFDS1400457854
                      </p>
                      <p className="mb-0">
                        <strong> Payment Mode :</strong> {payDetail?.method}
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <div className="card-header">
                    <h5 className="card-title mb-0">Payment Detail:</h5>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-sm table-borderless align-middle description-table mb-0">
                        <tbody>
                          <tr>
                            <td>Transactions:</td>
                            <td>
                              <span className="fw-medium">{payDetail?.id}</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Method</td>
                            <td>{payDetail?.method}</td>
                          </tr>
                          <tr>
                            <td>Card Number:</td>
                            <td>
                              {payDetail?.method === 'card' && (
                                <span className="fw-medium">
                                  XXXX XXXX XXXX {payDetail?.card?.last4}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Card Holder Name</td>
                            <td>
                              <span className="fw-medium">
                                {' '}
                                {payDetail?.method === 'card' && (
                                  <span className="fw-medium">
                                    {payDetail?.card?.name ||
                                      'No Name Available'}
                                  </span>
                                )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Amount</td>
                            <td>
                              <span className="fw-medium">
                                ₹ {payDetail?.amount}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg={12}>
            <Card id="track-order">
              <div className="card-header d-flex  align-items-center gap-3">
                <h5 className="card-title flex-grow-1 mb-0">Order Status</h5>
                <div className="flex-shrink-0"></div>
              </div>
              <div className="card-body">
                <Row className="justify-content-between">
                  {orderDetail?.order_status !== ORDER_STATUS.CANCELED ? (
                    <>
                      <Col
                        lg={2}
                        className={`order-tracking text-center ${
                          orderDetail?.order_status === ORDER_STATUS.ACCEPTED ||
                          ORDER_STATUS.PENDING
                            ? 'completed'
                            : ''
                        }`}>
                        <span className="is-complete"></span>
                        <div className="card mt-3 mb-0">
                          <div className="card-body">
                            <h6 className="fs-17">Ordered</h6>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        className={`order-tracking text-center ${
                          orderDetail?.order_status === ORDER_STATUS.ACCEPTED ||
                          orderDetail?.order_status ===
                            ORDER_STATUS.ON_THE_WAY ||
                          orderDetail?.order_status ===
                            ORDER_STATUS.DELIVERY_PERSON_ASSIGN ||
                          orderDetail?.order_status === ORDER_STATUS.DELIVERED
                            ? 'completed'
                            : ''
                        }`}>
                        <span className="is-complete"></span>
                        <div className="card mt-3 mb-0">
                          <div className="card-body">
                            <h6 className="fs-17">Order Shipped</h6>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        className={`order-tracking text-center ${
                          orderDetail?.order_status ===
                            ORDER_STATUS.ON_THE_WAY ||
                          orderDetail?.order_status ===
                            ORDER_STATUS.DELIVERY_PERSON_ASSIGN ||
                          orderDetail?.order_status === ORDER_STATUS.DELIVERED
                            ? 'completed'
                            : ''
                        }`}>
                        <span className="is-complete"></span>
                        <div className="card mt-3 mb-0">
                          <div className="card-body">
                            <h6 className="fs-17">In Transist</h6>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        className={`order-tracking text-center ${
                          orderDetail?.order_status ===
                            ORDER_STATUS.DELIVERY_PERSON_ASSIGN ||
                          orderDetail?.order_status === ORDER_STATUS.DELIVERED
                            ? 'completed'
                            : ''
                        }`}>
                        <span className="is-complete"></span>
                        <div className="card mt-3 mb-0">
                          <div className="card-body">
                            <h6 className="fs-17">Out For Delivery</h6>
                          </div>
                        </div>
                      </Col>
                      <Col
                        lg={2}
                        className={`order-tracking text-center ${
                          orderDetail?.order_status === ORDER_STATUS.DELIVERED
                            ? 'completed'
                            : ''
                        }`}>
                        <span className="is-complete"></span>
                        <div className="card mt-3 mb-0">
                          <div className="card-body">
                            <h6 className="fs-17">Delivered</h6>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center text-danger fs-5 fw-semibold mt-4">
                      <span className="me-2">Order Canceled</span>
                      <span role="img" aria-label="sad-face">
                        😞
                      </span>
                    </div>
                  )}
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
        <ModalContainer
          showModal={modalFlag}
          handleClose={modalToggle}
          modalTitle="Order Cancel Order"
          modalBody={<OrderCancel handleClose={modalToggle} />}
        />
        <ModalContainer
          showModal={modalFlag1}
          handleClose={() => {
            setModalFlag1(false);
          }}
          modalTitle="Gift Details"
          modalBody={
            orderDetail?.order_detail != null &&
            orderDetail?.order_detail.length > 0 ? (
              orderDetail?.order_detail.map((order: OrderDetailItemType, i) => (
                <div key={i}>
                  {order.is_gift !== null && (
                    <>
                      <p>Gift From: {order.gift_from}</p>
                      <p>Gift Message: {order.gift_message}</p>
                    </>
                  )}
                </div>
              ))
            ) : (
              <p>No gift details available.</p>
            )
          }
        />
      </Container>
    </div>
  );
};

export default OrderDetails;
