import React from 'react';

interface InfoCircleSvgProps {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  width?: string;
  height?: string;
}

const CustomInfoCircle: React.FC<InfoCircleSvgProps> = ({
  onMouseEnter,
  onMouseLeave,
  width,
  height,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      preserveAspectRatio="xMidYMid meet"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      fill="currentColor">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-13c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM11 17h2v-6h-2v6z" />
    </svg>
  );
};

export default CustomInfoCircle;
