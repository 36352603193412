import React, {useEffect, useState} from 'react';

const Navdata = (): React.JSX.Element => {
  // state data
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [isLocalization, setIsLocalization] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isCoupon, setIsCoupon] = useState(false);

  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  function updateIconSidebar(e: any): void {
    if (e?.target?.getAttribute('subitems') != null) {
      const ul: any = document.getElementById('two-column-menu');
      const iconItems: any = ul.querySelectorAll('.nav-icon.active');
      const activeIconItems = [...iconItems];
      activeIconItems.forEach(item => {
        item.classList.remove('active');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Ecommerce') {
      setIsEcommerce(false);
    }
    if (iscurrentState !== 'Orders') {
      setIsOrder(false);
    }
    if (iscurrentState !== 'Coupon') {
      setIsCoupon(false);
    }

    if (iscurrentState !== 'Invoice') {
      setIsInvoice(false);
    }
    if (iscurrentState !== 'Shipping') {
      setIsShipping(false);
    }
    if (iscurrentState !== 'Localization') {
      setIsLocalization(false);
    }
    if (iscurrentState !== 'Auth') {
      setIsAuth(false);
    }
  }, [
    iscurrentState,
    isEcommerce,
    isOrder,
    isInvoice,
    isShipping,
    isLocalization,
    isAuth,
  ]);

  const menuItems: any = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'bi bi-speedometer2',
      link: '/dashboard',
      badgeName: 'Hot',
      badgeColor: 'danger',
    },
    // {
    //   id: 'products',
    //   label: 'Products',
    //   icon: 'bi bi-box-seam',
    //   link: '/#',
    //   click: function (e: any) {
    //     e.preventDefault();
    //     setIsEcommerce(!isEcommerce);
    //     setIscurrentState('Ecommerce');
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isEcommerce,
    //   subItems: [
    //     {
    //       id: 'listview',
    //       label: 'List View',
    //       link: '/productlisting',
    //       parentId: 'products',
    //     },
    //     {
    //       id: 'gridview',
    //       label: 'Grid View',
    //       link: '#',
    //       parentId: 'products',
    //     },
    //     {
    //       id: 'overview',
    //       label: 'Overview',
    //       link: '#',
    //       parentId: 'products',
    //     },
    //     {
    //       id: 'createproduct',
    //       label: 'Create Product',
    //       link: '#',
    //       parentId: 'products',
    //     },
    //     {
    //       id: 'categories',
    //       label: 'Categories',
    //       link: '#',
    //       parentId: 'products',
    //     },
    //   ],
    // },
    {
      id: 'warehouse',
      label: 'Ware House',
      icon: 'bi bi-person-bounding-box',
      link: '/warehouse-userlist',
    },
    {
      id: 'userlist',
      label: 'User List',
      icon: ' ph-user-circle-thin',
      link: '/user-list',
    },
    {
      id: 'order',
      label: 'Orders',
      icon: 'bi bi-cart4',
      link: '/order-list',
    },
    // {
    //   id: 'invoice',
    //   label: 'Invoice',
    //   icon: 'bi bi-archive',
    //   link: '/invoice-list',
    // },

    {
      id: 'coupons',
      label: 'Coupon',
      icon: 'bi bi-tag',
      link: '/coupons',
      click: function (e: any) {
        e.preventDefault();
        setIsCoupon(!isCoupon);
        setIscurrentState('Coupon');
        updateIconSidebar(e);
      },
      stateVariables: isCoupon,
      subItems: [
        {
          id: 'listview',
          label: 'Coupons',
          link: '/coupon',
          parentId: 'coupons',
        },
        {
          id: 'analysis',
          label: 'Analysis',
          link: '/analysis',
          parentId: 'coupons',
        },
      ],
    },
    {
      id: 'notifyme',
      label: 'Notified Products',
      icon: 'bi bi-bell',
      link: '/notified-products',
    },
    {
      id: 'policy',
      label: 'Policy Mangement',
      icon: 'bi bi-tag',
      link: '/policy-list',
    },
    {
      id: 'reviewsAndRatings',
      label: 'Reviews & Ratings',
      icon: 'bi bi-star',
      link: '/reviews-ratings',
    },
    {
      id: 'wishlist',
      label: 'Wishlist',
      icon: 'bi bi-heart',
      link: '/wishlist',
    },
    {
      id: 'areaSpecificSales',
      label: 'Area Specific Sales',
      icon: 'bi bi-tag',
      link: '/area-specific-sales',
    },
    {
      id: 'stockmanagement',
      label: 'Stock Management',
      icon: 'bi bi-box',
      link: '/stock-management',
    },
    {
      id: 'paymenttracking',
      label: 'Payment Tracking',
      icon: 'bi bi-currency-dollar',
      link: '/payment-tracking',
    },
    {
      id: 'Support',
      label: 'Support',
      icon: 'bi bi-tag',
      link: '/support',
    },
    {
      id: 'Discounts',
      label: 'Price Up & Down',
      icon: 'bi bi-tag',
      link: '/discount',
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
