import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {type ModalContainerProps} from 'utils/TypesConfig';

const ModalContainer: React.FC<ModalContainerProps> = ({
  showModal,
  handleClose,
  modalTitle,
  modalBody,
}) => {
  const {pathname} = useLocation();
  const [isChatPage, setIsChatPage] = useState(false);
  useEffect(() => {
    if (pathname.split('/').includes('view-chat')) {
      setIsChatPage(!isChatPage);
    } else {
      setIsChatPage(false);
    }
  }, [pathname]);
  return (
    <div>
      <Modal
        id="showModal"
        className="fade zoomIn"
        size="lg"
        data-testid="showModal"
        show={showModal}
        onHide={handleClose}
        centered>
        <Modal.Header
          className={`px-4 pt-4 ${isChatPage ? 'd-none' : ''}`}
          role="closeModal"
          closeButton>
          <h5 className="modal-title fs-18" id="exampleModalLabel">
            {modalTitle}
          </h5>
        </Modal.Header>
        <Modal.Body className={isChatPage ? '' : 'p-4'}>{modalBody}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalContainer;
