import React, {useState, useEffect} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
// import images
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import {variables} from 'utils/constant';
import {removeLoginToken} from 'slices/auth/login/reducer';
import {createSelector} from 'reselect';

const ProfileDropdown = (): React.JSX.Element => {
  const [userName, setUserName] = useState<any>('Admin');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectLayoutProperties = createSelector(
    (state: any) => state.Profile,
    profile => ({
      success: profile.success,
      name: profile.user.userName,
    }),
  );

  const handelLogout = (): void => {
    console.log('jn');
    Cookies.remove(variables.ecommerce_admin);
    localStorage.removeItem(variables.ADMIN_USERNAME);
    dispatch(removeLoginToken());
    navigate('/login');
  };

  const {success} = useSelector(selectLayoutProperties);
  const {name}: any = useSelector(selectLayoutProperties);

  useEffect(() => {
    const authUserString = localStorage.getItem('authUser');
    if (authUserString != null) {
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser') ?? '{}');
        const getUserName =
          obj.displayName === undefined ? 'Admin' : obj.displayName;
        setUserName(getUserName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        const obj = JSON.parse(localStorage.getItem('authUser') ?? '{}');
        console.log(
          '🚀 ~ file: ProfileDropdown.tsx:49 ~ useEffect ~ obj:',
          obj,
        );
        setUserName(obj.username);
      }
    }
  }, [success, name]);

  const UserProfileImage = `${name?.first}${name?.last}`;

  return (
    <Dropdown className="ms-sm-3 header-item topbar-user">
      <Dropdown.Toggle
        type="button"
        className="btn bg-transparent border-0 arrow-none"
        id="page-header-user-dropdown">
        <span className="d-flex align-items-center">
          <img
            className="rounded-circle header-profile-user"
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
              UserProfileImage,
            )}&background=c7d2fe&color=3730a3&bold=true`}
            alt="Header Avatar"
          />
          <span className="text-start ms-xl-2">
            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
              {name?.first}
            </span>
            <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">
              {name?.last}
            </span>
          </span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-end">
        <h6 className="dropdown-header">Welcome {name?.first ?? userName}!</h6>
        {/* <Dropdown.Item href="/user-profile">
          <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{' '}
          <span className="align-middle">Profile</span>
        </Dropdown.Item> */}
        <Dropdown.Item>
          <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
          <span
            className="align-middle"
            data-key="t-logout"
            onClick={handelLogout}>
            Logout
          </span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
