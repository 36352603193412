import React from 'react';
import ModalContainer from 'Common/ModalContainer';
import {Form, Button, Col} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';

interface Props {
  showModal: any;
  setShowModal: any;
}

const RefundModal = ({showModal, setShowModal}: Props): JSX.Element => {
  const formikValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      order_id: '',
    },
    validationSchema: Yup.object().shape({
      order_id: Yup.number()
        .positive('order id must be in positive')
        .required('order is is required'),
    }),
    onSubmit: values => {
      try {
        void ApiUtils.refundPayment({id: values.order_id})
          .then((res: any) => {
            toast.success(res?.message);
            handleCloseModal();
          })
          .catch((err: any) => {
            toast.error(err?.response?.data?.message);
          });
      } catch (err: any) {
        toast.error(err?.message);
      }
    },
  });

  const handleCloseModal = (): any => {
    setShowModal(false);
    formikValidation.resetForm();
  };

  const modalData = (): JSX.Element => {
    return (
      <Col lg={12}>
        <Form>
          <div className="mb-3">
            <Form.Label htmlFor="order_id">Order Id</Form.Label>
            <Form.Control
              type="number"
              name="order_id"
              id="order_id"
              placeholder="Enter Order Id.."
              onChange={formikValidation.handleChange}
              onBlur={formikValidation.handleBlur}
              value={formikValidation.values.order_id ?? ''}
              isInvalid={
                !!(
                  Boolean(formikValidation.touched.order_id) &&
                  Boolean(formikValidation.errors.order_id)
                )
              }
            />
            {Boolean(formikValidation.touched.order_id) &&
            Boolean(formikValidation.errors.order_id) ? (
              <Form.Control.Feedback type="invalid" className="required-mark">
                {formikValidation.errors.order_id}
              </Form.Control.Feedback>
            ) : null}
          </div>
        </Form>
        <div className="hstack gap-2 justify-content-end">
          <Button
            onClick={() => {
              formikValidation.handleSubmit();
            }}
            variant="primary"
            type="button"
            id="add-btn">
            Refund Payment
          </Button>
        </div>
      </Col>
    );
  };

  return (
    <ModalContainer
      showModal={showModal}
      handleClose={handleCloseModal}
      modalTitle="Refund"
      modalBody={modalData()}
    />
  );
};

export default RefundModal;
