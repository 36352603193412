import ModalContainer from 'Common/ModalContainer';
import React from 'react';
import {Form, Button, Col} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import ApiUtils from 'api/ApiUtils';
interface Props {
  toogleStatus: any;
  showModal: any;
  details: {
    id: number;
    ticket_no: number;
    comment: string;
    issue_type: string;
    status_id: number;
    conclusion: string;
  };
}

const StatusModal = ({
  toogleStatus,
  showModal,
  details,
}: Props): JSX.Element => {
  const modalData = (): JSX.Element => {
    const formikValidation = useFormik({
      enableReinitialize: true,
      initialValues: {
        id: details?.id,
        status_id: details?.status_id ?? '',
        conclusion_message: details?.conclusion ?? '',
      },
      validationSchema: Yup.object().shape({
        status_id: Yup.number().required('Please Select Status'),
        conclusion_message: Yup.string().required(
          'Conclusion message is required',
        ),
      }),
      onSubmit: async values => {
        try {
          const response: any = await ApiUtils.updateTicketStatus(values);
          toast.success(response.message);
          toogleStatus();
        } catch (error: any | unknown) {
          toast.error('Something went wrong');
        }
      },
    });

    return (
      <Col lg={12}>
        <Form
          onSubmit={e => {
            e.preventDefault();
            formikValidation.handleSubmit();
          }}>
          <div className="mb-3">
            <Form.Label htmlFor="status_id" data-testid="status-modal-title">
              Status
            </Form.Label>
            <Form.Select
              className="form-select"
              name="status_id"
              id="status_id"
              onChange={formikValidation.handleChange}
              onBlur={formikValidation.handleBlur}
              value={formikValidation.values.status_id ?? ''}>
              <option disabled value="">
                Select Status
              </option>
              <option value={30}>Pending</option>
              <option value={31}>Open</option>
              <option value={32}>Closed</option>
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="conclusion_message">
              Conclusion Message
            </Form.Label>
            <Form.Control
              type="text"
              name="conclusion_message"
              id="conclusion_message"
              placeholder="Message.."
              onChange={formikValidation.handleChange}
              onBlur={formikValidation.handleBlur}
              value={formikValidation.values.conclusion_message ?? ''}
              isInvalid={
                !!(
                  Boolean(formikValidation.touched.conclusion_message) &&
                  Boolean(formikValidation.errors.conclusion_message)
                )
              }
            />
            {Boolean(formikValidation.touched.conclusion_message) &&
            Boolean(formikValidation.errors.conclusion_message) ? (
              <Form.Control.Feedback type="invalid" className="required-mark">
                {formikValidation.errors.conclusion_message}
              </Form.Control.Feedback>
            ) : null}
          </div>
          <div className="hstack gap-2 justify-content-end">
            <Button type="submit" variant="primary" id="add-btn">
              Update Status
            </Button>
          </div>
        </Form>
      </Col>
    );
  };

  return (
    <ModalContainer
      showModal={showModal}
      handleClose={toogleStatus}
      modalTitle="Status"
      modalBody={modalData()}
    />
  );
};

export default StatusModal;
