import React from 'react';
import {Col, Row} from 'react-bootstrap';
interface OrderDetail {
  customer_first_name: string;
  customer_last_name: string;
  phone_number: string;
  address_line_1: string;
  address_line_2: string;
  city_name: string;
  postcode: string;
}

interface OrderDetailsCardProps {
  orderDetail?: OrderDetail;
}
const OrderDetailsCard: React.FC<OrderDetailsCardProps> = ({
  orderDetail,
}): JSX.Element => {
  return (
    <div>
      <Row>
        <Col xxl={6} lg={6}>
          <div className="card bg-success bg-opacity-10 card-height border-0">
            <div className="card-body">
              <div className="d-flex gap-3">
                <div className="flex-grow-1">
                  <h6 className="fs-18 mb-3">Customer Info</h6>
                  <p className="mb-0 fw-medium">
                    {orderDetail?.customer_first_name +
                      ' ' +
                      orderDetail?.customer_last_name}
                  </p>
                  <p className="mb-0">{orderDetail?.phone_number}</p>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <div className="avatar-title bg-success-subtle text-success rounded fs-3">
                    <i className="ph-user-circle"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={6} lg={6}>
          <div className="card bg-primary bg-opacity-10 border-0">
            <div className="card-body">
              <div className="d-flex gap-3">
                <div className="flex-grow-1">
                  <h6 className="fs-18 mb-3">Shipping Address</h6>
                  <p className="mb-0">
                    {orderDetail?.address_line_1}, {orderDetail?.address_line_2}
                  </p>
                  <p className="mb-1">
                    {orderDetail?.city_name}, {orderDetail?.postcode}
                  </p>
                  <p className="mb-0">{orderDetail?.phone_number}</p>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <div className="avatar-title bg-primary-subtle text-primary rounded fs-3">
                    <i className="ph-map-pin"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetailsCard;
