import React, {useMemo} from 'react';
import {productList} from 'Common/data/product';
import TableContainer from 'Common/TableContainer';
import {Card, Col, Dropdown} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import moment from 'moment';
// representing a table of products listing.
const ProductTable = (): JSX.Element => {
  const [productItem, setProductItem] = React.useState(
    React.useMemo(() => productList, []),
  );

  const navigate = useNavigate();

  // Handles formatting and returning a valid date string.
  const handleValidDate = (date: any): string => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  // Handles formatting and returning a valid time string.
  const handleValidTime = (time: any): string => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    let meridiem = '';
    if (getHour >= 12) {
      meridiem = 'PM';
    } else {
      meridiem = 'AM';
    }
    const updateTime =
      moment(getTime, 'hh:mm').format('hh:mm') + ' ' + meridiem;
    return updateTime;
  };

  const handleAddProduct = (): any => {
    navigate('/product-create');
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        Cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="productCheckBox form-check-input"
              value={cell.row.original._id}
            />
          );
        },
      },
      {
        Header: 'Product name',
        Cell: (product: any) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-2">
              <div className="avatar-sm bg-light rounded p-1">
                <img
                  src={product.row.original.productImg}
                  alt=""
                  className="img-fluid d-block avatar-xs"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link
                  to="/apps-ecommerce-product-details"
                  className="d-block text-reset">
                  {' '}
                  {product.row.original.productTitle}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                Category :{' '}
                <span className="fw-medium">
                  {' '}
                  {product.row.original.category}
                </span>
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Stock',
        accessor: 'stock',
        Filter: false,
      },
      {
        Header: 'Rate',
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <span>
              <span className="badge bg-light text-body fs-12 fw-medium">
                <i className="mdi mdi-star text-warning me-1"></i>
                {cellProps.rating}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Filter: false,
      },
      {
        Header: 'Orders',
        accessor: 'orders',
        Filter: false,
      },
      {
        Header: 'Publish',
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <span>
              {handleValidDate(cellProps.publish)}
              <small className="text-muted ms-1">
                {handleValidTime(cellProps.publish)}
              </small>
            </span>
          );
        },
      },
      {
        Header: 'Action',
        Cell: (cellProps: any) => {
          return (
            <Dropdown className="text-center">
              <Dropdown.Toggle
                href="#"
                className="btn btn-ghost-primary btn-icon btn-sm arrow-none">
                <i className="mdi mdi-dots-horizontal" />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item href="/product-create">
                    <i className="ri-pencil-fill align-bottom me-2 text-muted" />{' '}
                    Edit
                  </Dropdown.Item>
                </li>
                <li>
                  <Dropdown.Item
                    onClick={() => {
                      const dataCopy = [...productItem];
                      dataCopy.splice(cellProps.row.index, 1);
                      setProductItem(dataCopy);
                    }}
                    className="remove-list">
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />
                    Delete
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [],
  );
  return (
    <Col xxl={12}>
      <Card>
        <Card.Body>
          <TableContainer
            columns={columns}
            data={productItem ?? []}
            isGlobalFilter={true}
            isAddUserList={false}
            customPageSize={10}
            isAddOptions={true}
            isBordered={true}
            iscustomPageSize={true}
            className="custom-header-css"
            tableClass="table-centered align-middle table-nowrap mb-0"
            theadClass="text-muted table-light"
            isProductsFilter={true}
            SearchPlaceholder="Search Products..."
            onClick={handleAddProduct}
            buttonText="Add Product"
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ProductTable;
