import {DOCUMENT_TITLE} from 'Common/constants/layout';
import Cookies from 'js-cookie';
import React from 'react';

// redux
import {useNavigate} from 'react-router-dom';
import {variables} from 'utils/constant';

const Logout = (): React.JSX.Element => {
  document.title = DOCUMENT_TITLE.LOGOUT;

  const navigate = useNavigate();

  Cookies.remove(variables.ecommerce_admin);

  navigate('/login');

  // Return null since this component doesn't render any UI
  return <></>;
};

export default Logout;
