import ApiUtils from 'api/ApiUtils';
import React, {type ChangeEvent, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Form, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import BreadCrumb from 'Common/BreadCrumb';
import FullImagePreviewer from 'pages/Support/FullImagePreviewer';
import {SUPPORT_STATUS} from 'Common/constants/layout';

interface ChatImage {
  id: number;
  image: string;
  customer_support_id: number | null;
  customer_support_chat_id: number | null;
  created_at: string;
}
interface ChatDataProps {
  id: number;
  comment: string;
  created_at: string;
  customer_support_chat: CustomerSupportChat[];
  customer_support_image: ChatImage[];
  status_id: number;
}
interface CustomerSupportChat {
  id: number;
  customer_support_id: number;
  message: string;
  created_by_admin: number;
  created_by_user: number | null;
  created_at: string;
  chat_support_image: ChatImage[];
}
function Chat(): React.JSX.Element {
  const {chatId} = useParams();
  const [chatData, setChatData] = useState<ChatDataProps[]>([]);

  const [inputMsg, setInputMsg] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const [showModalImg, setShowModalImg] = useState(false);
  const [fullImageUrl, setFullImageUrl] = useState('');
  function toggleImagePreview(): void {
    setShowModalImg(!showModalImg);
  }
  const fetchChat = async (): Promise<void> => {
    try {
      const response: any = await ApiUtils.getSupportChatList(chatId);
      setChatData(response?.data);
    } catch (err) {
      toast.error('Something went wrong');
    }
  };
  useEffect(() => {
    if (chatId != null) {
      void fetchChat();
    }
  }, [chatId]);
  function formatDateTime(inputDateTimeString: string): string {
    const inputDateTime = new Date(inputDateTimeString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return inputDateTime.toLocaleString('en-US', options);
  }
  const handleSendMessage = async (e: any): Promise<void> => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('customer_support_id', chatId as unknown as string);
      formData.append('message', inputMsg);

      selectedFiles.forEach((file, index) => {
        formData.append(`image[${index}]`, file);
      });
      const response: any = await ApiUtils.createChatting(formData);
      setInputMsg('');
      setSelectedFiles([]);
      setImagePreviews([]);
      void fetchChat();
      toast.success(response?.message);
    } catch (err) {
      toast.error('Something went wrong');
    }
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const files = event.target.files;

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (files) {
      const imageFiles = Array.from(files).filter(file =>
        file.type.startsWith('image/'),
      );

      setSelectedFiles(prevFiles => [...prevFiles, ...imageFiles]);
      const newFiles = Array.from(imageFiles)?.map(async file => {
        const reader = new FileReader();

        return await new Promise<string>(resolve => {
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.readAsDataURL(file);
        });
      });

      void Promise.all(newFiles).then(filePreviews => {
        const newImagePreviews = filePreviews.filter(
          file => typeof file === 'string',
        );

        setImagePreviews(prevImagePreviews => [
          ...prevImagePreviews,
          ...newImagePreviews,
        ]);
      });
    }
  };
  const removeFile = (index: number): void => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setImagePreviews(prevImagePreviews =>
      prevImagePreviews.filter((_, i) => i !== index),
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Chat"
            pageTitle="Support List"
            pageLink="/support"
          />

          <Row>
            <Col xxl={12}>
              <Card className="chat-card h-100">
                <Card.Body>
                  <section className="">
                    <main className="msger-chat">
                      <div className="msg left-msg">
                        <div className="msg-bubble">
                          <div className="msg-info">
                            <div className="msg-info-name">User</div>
                            <div className="msg-info-time">
                              {formatDateTime(chatData[0]?.created_at)}
                            </div>
                          </div>
                          <div className="msg-text">{chatData[0]?.comment}</div>
                          {chatData[0]?.customer_support_image.length > 0 &&
                            chatData[0]?.customer_support_image?.map(
                              (dataImg: ChatImage) => {
                                return (
                                  <img
                                    key={dataImg.id}
                                    className="mt-2 cursor-pointer d-block"
                                    alt="image"
                                    src={dataImg?.image}
                                    width={150}
                                    height={150}
                                    onClick={async () => {
                                      setFullImageUrl(dataImg?.image);
                                      toggleImagePreview();
                                    }}
                                  />
                                );
                              },
                            )}
                        </div>
                      </div>
                      {chatData[0]?.customer_support_chat?.length > 0 &&
                        chatData[0]?.customer_support_chat?.map(
                          (data: CustomerSupportChat) => {
                            return (
                              <div
                                key={data.id}
                                className={
                                  data?.created_by_admin != null
                                    ? 'msg right-msg'
                                    : data?.created_by_user != null
                                      ? 'msg left-msg'
                                      : ''
                                }>
                                <div className="msg-bubble">
                                  <div className="msg-info">
                                    <div className="msg-info-name">
                                      {data?.created_by_admin != null
                                        ? 'Admin'
                                        : data?.created_by_user != null
                                          ? 'User'
                                          : ''}
                                    </div>
                                    <div className="msg-info-time">
                                      {formatDateTime(data?.created_at)}
                                    </div>
                                  </div>
                                  <div className="msg-text">
                                    {data?.message}
                                  </div>
                                  {data?.chat_support_image.length > 0 &&
                                    data?.chat_support_image?.map(
                                      (dataImg: ChatImage) => {
                                        return (
                                          <img
                                            key={dataImg?.id}
                                            className="mt-2 cursor-pointer d-block"
                                            alt="image"
                                            src={dataImg?.image}
                                            width={150}
                                            height={150}
                                            onClick={async () => {
                                              setFullImageUrl(dataImg?.image);
                                              toggleImagePreview();
                                            }}
                                          />
                                        );
                                      },
                                    )}
                                </div>
                              </div>
                            );
                          },
                        )}
                    </main>
                    {chatData?.[0]?.status_id !== SUPPORT_STATUS.CLOSED && (
                      <>
                        <form className="msger-inputarea">
                          <Form.Control
                            type="text"
                            name="conclusion_message"
                            id="conclusion_message"
                            placeholder="Message.."
                            onChange={e => {
                              setInputMsg(e.target.value);
                            }}
                            value={inputMsg}
                          />
                          <label htmlFor="upload" className="cursor-pointer">
                            <i className="mdi mdi-attachment text-muted fs-16 align-middle me-1"></i>{' '}
                          </label>
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="d-none"
                            onChange={handleFileChange}
                            multiple
                          />
                          <Button
                            type="submit"
                            disabled={!(inputMsg.length > 0)}
                            onClick={handleSendMessage}
                            placeholder="Write Message"
                            className="msger-send-btn"
                            variant="primary">
                            Send
                          </Button>
                        </form>
                        <div className="mt-2 mb-3 pb-3 d-flex flex-wrap gap-4">
                          {imagePreviews?.map((filePreview, index) => (
                            <div
                              key={filePreview}
                              className={`mb-2 image-preview ${
                                index % 6 === 2 ? 'w-1/6' : 'w-full md:w-1/6'
                              }`}>
                              <img
                                src={filePreview}
                                alt={`Image Preview ${index + 1}`}
                                width="100%"
                                height="100%"
                              />
                              <i
                                className="mdi mdi-close-box text-muted fs-16 align-middle me-1 cursor-pointer remove-preview-img"
                                onClick={() => {
                                  removeFile(index);
                                }}></i>{' '}
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </section>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <FullImagePreviewer
        toggleImagePreview={toggleImagePreview}
        showModalImg={showModalImg}
        fullImageUrl={fullImageUrl}
      />
    </React.Fragment>
  );
}

export default Chat;
