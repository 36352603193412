/* eslint-disable no-unexpected-multiline */

import {apiEndpoints} from 'utils/apis';
import api from './Index';

// Define the structure of an API Call
interface ApiCall {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: string;
  data?: any;
  params?: any;
  id?: number | string;
}
// Generic CRUD Function
const genericApiCall = async ({
  method,
  endpoint,
  data,
  params,
  id,
}: ApiCall): Promise<unknown> => {
  const url = id != null ? `${endpoint}?id=${id}` : endpoint;
  return await new Promise((resolve, reject) => {
    (api as any)
      [method.toLowerCase()](url, data, {params})
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

// Define individual calls using the new structure
const ApiUtils = {
  authLogin: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.LOGIN,
      data: params,
    }),

  verifyOTP: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.VERIFY_OTP,
      data: params,
    }),

  getProfile: async () =>
    await genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_PROFILE,
    }),

  forgetPassword: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.FORGET_PASSWORD,
      data: params,
    }),
  resetPassword: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.RESET_PASSWORD,
      data: params,
    }),
  policyList: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_POLICY_LIST}?${params}`,
    }),
  deletePolicy: async (params: any) =>
    await genericApiCall({
      method: 'DELETE',
      endpoint: `${apiEndpoints.DELETE_POLICY}?${params}`,
    }),
  couponList: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.LIST_COUPON}?${params}`,
    }),
  deleteCoupon: async (params: any) =>
    await genericApiCall({
      method: 'DELETE',
      endpoint: `${apiEndpoints.DELETE_COUPON}?${params}`,
    }),
  addPolicy: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.ADD_POLICY,
      data: params,
    }),
  getSinglePolicy: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_POLICY_LIST}?id=${params}`,
    }),
  updatePolicy: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.UPDATE_POLICY,
      data: params,
    }),
  couponMediaType: async () =>
    await genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_COUPON_MEDIA_TYPE,
    }),
  warehouseRegister: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.WAREHOUSE_REGISTER,
      data: params,
    }),
  getWarehouseList: async (params: any) => {
    return await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_WAREHOUSELIST}${params}`,
    });
  },
  deleteWarehouse: async (params: any) => {
    return await genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.DELETE_WAREHOUSE,
      id: params,
    });
  },
  updateWarehouse: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.UPDATE_WAREHOUSE,
      data: params,
    });
  },
  getOrderList: async (params: any) => {
    return await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_ORDERLIST}${params}`,
    });
  },
  getRecentOrders: async () => {
    return await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_ORDERLIST}?${`sort_direction=desc`}`,
    });
  },
  getPaymentTrackingList: async (params: any) => {
    return await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_PAYMENT_TRACKING}${params}`,
    });
  },
  getCategory: async () =>
    await genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_CATEGORY,
    }),
  getProductStockList: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.GET_STOCK,
      data: params,
    });
  },
  createCoupon: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_COUPON,
      data: params,
    });
  },
  updateCoupon: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.UPDATE_COUPON,
      data: params,
    });
  },
  getProductByCategoryId: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.GET_PRODUCT_BY_CATEGORY_ID,
      data: params,
    });
  },
  getOptionByProductById: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.GET_OPTION_BY_PRODUCT_ID,
      data: params,
    });
  },
  getOptionValueByOptionId: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.GET_OPTION_VALUE_BY_OPTION_ID,
      data: params,
    });
  },
  getAreaSpecificSalesList: async (params: any) => {
    return await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_AREA_SPECIFIC_SALES}${params}`,
    });
  },
  getSupportTicketList: async (params: any) => {
    return await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_SUPPORT_TICKET_LIST}?${params}`,
    });
  },
  updateTicketStatus: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.UPDATE_TICKET_STATUS,
      data: params,
    });
  },
  getSupportChatList: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.SUPPORT_CHAT_BY_TICKET}/${params}`,
    }),
  deleteSupportTicket: async (params: any) =>
    await genericApiCall({
      method: 'DELETE',
      endpoint: `${apiEndpoints.DELETE_SUPPORT_TICKET}?${params}`,
    }),
  createChatting: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CHATTING_SUPPORT,
      data: params,
    }),
  getProductById: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_PRODUCT}/${params}`,
    }),

  getAllProduct: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_PRODUCT}?${params}`,
    }),
  getStatus: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_STATUS}?${params}`,
      params,
    }),

  getDasbordData: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_DASHBOARD_DATA}?${params}`,
    }),
  getCustomerList: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_CUSTOMER_LIST}?${params}`,
    }),
  updateCustomerDetails: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.UPDATE_CUSTOMER,
      data: params,
    }),
  deleteCustomer: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.DELET_CUSTOMER,
      data: params,
    }),

  getCouponAnalysisList: async (params: any) => {
    return await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_COUPON_ANALYSIS}${params}`,
    });
  },
  getWishlistedItem: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_WISHLISTED_ITEM}?${params}`,
    }),
  getNotifiedItemList: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_NOTIFIED_ITEM}?${params}`,
    }),
  getOrderCount: async () => {
    return await genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.ORDER_COUNT,
    });
  },
  refundPayment: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.REFUND_PAYMENT,
      data: params,
    }),
  getDiscountList: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_DISCOUNT}${params}`,
    }),
  createDiscount: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_DISCOUNT,
      data: params,
    }),
  updateDiscount: async (params: any) =>
    await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.UPDATE_DISCOUNT,
      data: params,
    }),
  deleteDiscount: async (params: any) =>
    await genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.DELETE_DISCOUNT,
      id: params,
    }),
  updateOrderStatus: async (params: any) => {
    return await genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.UPDATE_ORDER_STATUS,
      data: params,
    });
  },
  downloadSalesReport: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_SALES_REPORT}?${params}`,
    }),
  getCity: async () =>
    await genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.CITY,
    }),
  getCityData: async (params: string) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.CITY}?state_id=${params}`,
    }),
  getCountries: async () =>
    await genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.COUNTRIES,
    }),
  getStates: async (params: any) =>
    await genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.STATE}?country_id=${params}`,
    }),
};

export default ApiUtils;
