// Import Images
import avatar1 from 'assets/images/users/avatar-1.jpg';
import avatar2 from 'assets/images/users/avatar-2.jpg';
import avatar3 from 'assets/images/users/avatar-3.jpg';
import avatar4 from 'assets/images/users/avatar-4.jpg';
import avatar5 from 'assets/images/users/avatar-5.jpg';
import avatar6 from 'assets/images/users/avatar-6.jpg';
import avatar7 from 'assets/images/users/avatar-7.jpg';
import avatar8 from 'assets/images/users/avatar-8.jpg';

const ListView = [
  {
    id: '#TTB30280001',
    customer: 'Neil Collins',
    email: 'ryan.dyer@toner.com',
    country: 'Brazil',
    date: '22 May, 2022 ',
    time: '10:45 AM',
    amt: '$415.96',
    paystatus: 'Paid',
    img: avatar1,
  },
  {
    id: '#TTB30280002',
    customer: 'Alfred Hurst',
    email: 'alfredH@toner.com',
    country: 'Brazil',
    date: '07 Jan, 2023',
    time: '8:34 AM',
    amt: '$875',
    paystatus: 'Unpaid',
    img: avatar2,
  },
  {
    id: '#TTB30280003',
    customer: 'Tommy Carey',
    email: 'careytommy@toner.com',
    country: 'Brazil',
    date: '28 Apr, 2022',
    time: '8:34 PM',
    amt: '  $875',
    paystatus: 'Paid',
    img: avatar3,
  },
  {
    id: '#TTB30280004',
    customer: 'Cassius Brock',
    email: 'brock@toner.com',
    country: 'Brazil',
    date: '01 Jul, 2022',
    time: '8:48 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar4,
  },
  {
    id: '#TTB30280005',
    customer: 'Camilla Winters',
    email: 'camilla@toner.com',
    country: 'Brazil',
    date: '04 Mar, 2021 ',
    time: '11:00 AM',
    amt: '$875',
    paystatus: 'Refund',
    img: avatar5,
  },
  {
    id: '#TTB30280006',
    customer: 'Gabrielle Holden',
    email: 'gabrielle@toner.com',
    country: 'Brazil',
    date: '07 Apr, 2022',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar6,
  },
  {
    id: '#TTB30280007',
    customer: 'Kristina Hooper',
    email: 'kristina@toner.com',
    country: 'Brazil',
    date: '30 Mar, 2022',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Cancell',
    img: avatar7,
  },
  {
    id: '#TTB30280008',
    customer: 'Alina Holland',
    email: 'hollandalina@toner.com',
    country: 'Brazil',
    date: '07 Apr, 2021 ',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar8,
  },
  {
    id: '#TTB30280009',
    customer: 'Christian Cardenas',
    email: 'id.erat@aol.org',
    country: 'Brazil',
    date: '26 Jan, 2022',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar1,
  },
  {
    id: '#TTB30280010',
    customer: 'Edward Rogers',
    email: 'edwardro@toner.com',
    country: 'Brazil',
    date: '24 Mar, 2021',
    time: '16:12 AM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar2,
  },
  {
    id: '#TTB30280011',
    customer: 'Hilel Gillespie',
    email: 'enim.nunc@yahoo.edu',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar3,
  },
  {
    id: '#TTB30280012',
    customer: 'Randall Stafford',
    email: 'eget.lacus@outlook.org',
    country: 'Brazil',
    date: ' 23 Mar, 2021',
    time: ' 9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar4,
  },
  {
    id: '#TTB30280013',
    customer: 'Fletcher Jones',
    email: 'sapien.cursus@google.couk',
    country: 'Brazil',
    date: ' 23 Mar, 2021',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar5,
  },
  {
    id: '#TTB30280014',
    customer: 'Donovan Sparks',
    email: 'urna.convallis@yahoo.net',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: ' 9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar6,
  },
  {
    id: '#TTB30280015',
    customer: 'Sage Gardner',
    email: 'consequat.enim@google.com',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar7,
  },
  {
    id: '#TTB30280016',
    customer: 'Paki Grimes',
    email: 'ante.lectus.convallis@google.com',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar1,
  },
  {
    id: '#TTB30280017',
    customer: 'James Diaz',
    email: 'nascetur@yahoo.com',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar2,
  },
  {
    id: '#TTB30280018',
    customer: 'Karen Monroe',
    email: 'ac.ipsum@google.com',
    country: 'Brazil',
    date: '23 Mar, 2021 ',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar3,
  },
  {
    id: '#TTB30280019',
    customer: 'Vincent Weeks',
    email: 'metus.facilisis@hotmail.edu',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar4,
  },
  {
    id: '#TTB30280020',
    customer: 'Miriam Dickson',
    email: 'nunc.ac@icloud.ca',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: ' 9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar5,
  },
  {
    id: '#TTB30280021',
    customer: 'Ashton Head',
    email: 'cras@outlook.edu',
    country: 'Brazil',
    date: '23 Mar, 2021 ',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar6,
  },
  {
    id: '#TTB30280022',
    customer: 'Linus Martin',
    email: 'fringilla.est.mauris@google.edu',
    country: 'Brazil',
    date: '23 Mar, 2021',
    time: '9:58 PM',
    amt: '$875',
    paystatus: 'Paid',
    img: avatar7,
  },
];

export {ListView};
