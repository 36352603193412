import React, {useState, useMemo, useCallback} from 'react';
import {Button, Card, Col, Dropdown, Row} from 'react-bootstrap';
import TableContainer from 'Common/TableContainer';
import {ListView} from '../../../Common/data/invoiceListView';
import {Link} from 'react-router-dom';

const InvoiceListTable = (): JSX.Element => {
  // const [modalAddUserModals, setmodalAddUserModals] = useState<boolean>(false);
  const [isMultiDeleteButton, setIsMultiDeleteButton] =
    useState<boolean>(false);

  // const AddUserModals = (): void => {
  //   setmodalAddUserModals(!modalAddUserModals);
  // };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById('checkAll') as HTMLInputElement;
    const ele = document.querySelectorAll('.invoiceCheckBox');

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    checkedbox();
  }, []);

  const checkedbox = (): void => {
    const ele = document.querySelectorAll('.invoiceCheckBox:checked');
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkAll"
              onClick={() => {
                checkedAll();
              }}
            />
          </div>
        ),
        Cell: (cellProps: any) => {
          return (
            <div className="form-check">
              <input
                className="invoiceCheckBox form-check-input"
                type="checkbox"
                name="chk_child"
                value={cellProps.row.original.id}
                onChange={() => {
                  checkedbox();
                }}
              />
            </div>
          );
        },
        id: '#',
      },
      {
        Header: 'ID',
        accessor: (cellProps: any) => {
          return (
            <Link to="#" className="fw-medium">
              {cellProps.id}
            </Link>
          );
        },
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'User Name',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          return (
            <div className="d-flex align-items-center gap-2">
              <div className="flex-shrink-0">
                <img
                  src={cellProps.img}
                  alt=""
                  className="avatar-xs rounded-circle user-profile-img"
                />
              </div>
              <div className="flex-grow-1 ms-2 user_name">
                {cellProps.customer}
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Country',
        accessor: 'country',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Amount',
        accessor: 'amt',
        disableFilters: true,
        filterable: true,
      },
      {
        Header: 'Payment Status',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          switch (cellProps.paystatus) {
            case 'Paid':
              return (
                <span className="badge bg-success-subtle text-success">
                  {cellProps.paystatus}
                </span>
              );
            case 'Unpaid':
              return (
                <span className="badge bg-warning-subtle text-warning">
                  {cellProps.paystatus}
                </span>
              );
            case 'Refund':
              return (
                <span className="badge bg-primary-subtle text-primary">
                  {cellProps.paystatus}
                </span>
              );
            default:
              return (
                <span className="badge bg-danger-subtle text-danger">
                  {cellProps.paystatus}
                </span>
              );
          }
        },
      },
      {
        Header: 'Action',
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          return (
            <Dropdown>
              <Dropdown.Toggle
                href="#!"
                className="btn btn-soft-secondary btn-sm dropdown btn-icon arrow-none">
                <i className="ri-more-fill align-middle"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="dropdown-menu-end">
                <li>
                  <Dropdown.Item href="/invoice-detail">
                    <i className="ri-eye-fill align-bottom me-2 text-muted" />{' '}
                    View
                  </Dropdown.Item>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [checkedAll],
  );

  return (
    <Row>
      <Col lg={12}>
        <Card id="invoiceList">
          <Card.Header className="border-0">
            <div className="d-flex align-items-center">
              <h5 className="card-title mb-0 flex-grow-1">Invoices</h5>
              <div className="flex-shrink-0">
                <div className="d-flex gap-2 flex-wrap">
                  {isMultiDeleteButton && (
                    <Button variant="danger" className="btn-icon">
                      <i className="ri-delete-bin-2-line"></i>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Card.Header>

          <Card.Body className="p-0">
            <div>
              <TableContainer
                columns={columns ?? []}
                data={ListView ?? []}
                isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={true}
                customPageSize={10}
                pageValue={1}
                className="custom-header-css"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder="Search Invoice..."
              />
              <div className="noresult" style={{display: 'none'}}>
                <div className="text-center">
                  <h5 className="mt-2">Sorry! No Result Found</h5>
                  <p className="text-muted mb-0">
                    We've searched more than 150+ invoices We did not find any
                    invoices for you search.
                  </p>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default InvoiceListTable;
