import React, {useEffect, useState} from 'react';
import {Form, Col, Row, Button, InputGroup} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {regex, variables} from 'utils/constant';
import ApiUtils from 'api/ApiUtils';
import {toast} from 'react-toastify';

const WareHouseAddUser: React.FC<{
  handleClose: () => void;
  getUsersList: () => Promise<void>;
}> = ({handleClose, getUsersList}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [cityData, setCityData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const fetchCountryData = async (): Promise<void> => {
    const response: any = await ApiUtils.getCountries();
    setCountryData(response?.data);
  };

  useEffect(() => {
    void fetchCountryData();
  }, []);

  const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .required('Please Enter Your Password')
      .matches(
        regex.passwordRegex,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      ),
    confirm_password: Yup.string()
      .required('Please Enter Your Confirm Password')
      .oneOf([Yup.ref('password')], 'password must match'),

    phone: Yup.string()
      .required('Phone is required')
      .test('isTenDigit', 'Phone must be 10 digits', value => {
        if (typeof value === 'string' && value.trim() !== '') {
          // Check if the value is a 10-digit number
          return /^\d{10}$/.test(value);
        }
        return false; // Return false if the value is empty
      }),
    status_id: Yup.number().required('Status is required'),
    address_line_1: Yup.string().required('Address Line1 is required'),
    address_line_2: Yup.string().required('Address Line2 is required'),
    postcode: Yup.string().required('Postcode is required'),
    landmark: Yup.string().required('Landmark is required'),
    city_id: Yup.string().required('City is required'),
    state_id: Yup.string().required('State is required'),
    country_id: Yup.string().required('Country is required'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      phone: '',
      status_id: '',
      address_line_1: '',
      address_line_2: '',
      postcode: '',
      landmark: '',
      city_id: '',
      state_id: '',
      country_id: '',
    },
    validationSchema,
    onSubmit: async values => {
      // Handle form submission logic here
      try {
        // Make the login API call
        const registerResponse: any = await ApiUtils.warehouseRegister({
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          phone: values.phone,
          status_id: values.status_id,
          address: {
            address_line_1: values.address_line_1,
            address_line_2: values.address_line_2,
            city_id: values.city_id,
            postcode: values.postcode,
            landmark: values.landmark,
          },
        });
        handleClose();
        void getUsersList();
        toast.success(registerResponse.message);
      } catch (error: any | unknown) {
        if (
          Boolean(error.response) &&
          typeof error.response.data === 'object' &&
          'message' in error.response.data
        ) {
          toast.error(error.response.data.message);
          // handleClose();
        } else {
          // Something happened in setting up the request that triggered an Error

          toast.error('An unexpected error occurred.');
          handleClose();
        }
      }
    },
  });

  useEffect(() => {
    async function fetchStatesByCountry(): Promise<void> {
      try {
        const response: any = await ApiUtils.getStates(
          `${formik.values.country_id}`,
        );
        setStateData(response?.data);
      } catch (_err) {}
    }
    if (formik.values.country_id.length > 0) {
      void fetchStatesByCountry();
    }
  }, [formik.values.country_id]);

  useEffect(() => {
    async function fetchCitiesByState(): Promise<void> {
      try {
        const response: any = await ApiUtils.getCityData(
          `${formik.values.state_id}`,
        );
        setCityData(response?.data);
      } catch (_err) {}
    }
    if (formik.values.state_id.length > 0) {
      void fetchCitiesByState();
    }
  }, [formik.values.state_id]);

  return (
    <Form
      className="tablelist-form"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}>
      <Row>
        {/* Your form fields */}
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="first_name">First Name</Form.Label>
            <Form.Control
              type="text"
              id="first_name"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              isInvalid={
                (formik.touched.first_name ?? false) &&
                !(formik.errors.first_name == null)
              }
            />
            {(formik.touched.first_name ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.first_name}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="last_name">Last Name</Form.Label>
            <Form.Control
              type="text"
              id="last_name"
              name="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              isInvalid={
                (formik.touched.last_name ?? false) &&
                !(formik.errors.first_name == null)
              }
            />
            {(formik.touched.last_name ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="last_name">Email Address</Form.Label>
            <Form.Control
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              isInvalid={
                (formik.touched.email ?? false) &&
                !(formik.errors.email == null)
              }
            />
            {(formik.touched.email ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="password">Password</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                isInvalid={
                  (formik.touched.password ?? false) &&
                  !(formik.errors.password == null)
                }
              />
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}>
                {showPassword ? 'Hide' : 'Show'}
              </Button>
              {(formik.touched.password ?? false) && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="confirm_password">Confirm Password</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirm_password"
                name="confirm_password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirm_password}
                isInvalid={
                  (formik.touched.confirm_password ?? false) &&
                  !(formik.errors.confirm_password == null)
                }
              />
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}>
                {showConfirmPassword ? 'Hide' : 'Show'}
              </Button>
              {(formik.touched.confirm_password ?? false) && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirm_password}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="phone">Phone Number</Form.Label>
            <Form.Control
              type="number"
              id="phone"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              maxLength={10}
              isInvalid={
                (formik.touched.phone ?? false) &&
                !(formik.errors.phone == null)
              }
            />
            {(formik.touched.phone ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="postcode">Postcode</Form.Label>
            <Form.Control
              type="text"
              id="postcode"
              name="postcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postcode}
              isInvalid={
                (formik.touched.postcode ?? false) &&
                !(formik.errors.postcode == null)
              }
            />
            {(formik.touched.postcode ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.postcode}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="city_id">Country</Form.Label>
            <Form.Select
              as="select"
              id="country_id"
              name="country_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country_id}
              isInvalid={
                (formik.touched.country_id ?? false) &&
                !(formik.errors.country_id == null)
              }>
              <option value="" label="Select Country" />
              {countryData?.map((el: any) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
            {(formik.touched.country_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.country_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="city_id">State</Form.Label>
            <Form.Select
              as="select"
              id="state_id"
              name="state_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state_id}
              isInvalid={
                (formik.touched.state_id ?? false) &&
                !(formik.errors.state_id == null)
              }>
              <option value="" label="Select State" />
              {stateData?.map((el: any) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
            {(formik.touched.state_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.state_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="city_id">City</Form.Label>
            <Form.Select
              as="select"
              id="city_id"
              name="city_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city_id}
              isInvalid={
                (formik.touched.city_id ?? false) &&
                !(formik.errors.city_id == null)
              }>
              <option value="" label="Select city" />
              {cityData?.map((el: any) => (
                <option key={el.id} value={el.id}>
                  {el.name}
                </option>
              ))}
            </Form.Select>
            {(formik.touched.city_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.city_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="address_line_1">Address Line1</Form.Label>
            <Form.Control
              type="text"
              id="address_line_1"
              name="address_line_1"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address_line_1}
              isInvalid={
                (formik.touched.address_line_1 ?? false) &&
                !(formik.errors.address_line_1 == null)
              }
            />
            {(formik.touched.address_line_1 ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_line_1}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="address_line_2">Address Line2</Form.Label>
            <Form.Control
              type="text"
              id="address_line_2"
              name="address_line_2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address_line_2}
              isInvalid={
                (formik.touched.address_line_2 ?? false) &&
                !(formik.errors.address_line_2 == null)
              }
            />
            {(formik.touched.address_line_2 ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_line_2}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="landmark">Landmark</Form.Label>
            <Form.Control
              type="text"
              id="landmark"
              name="landmark"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.landmark}
              isInvalid={
                (formik.touched.landmark ?? false) &&
                !(formik.errors.landmark == null)
              }
            />
            {(formik.touched.postcode ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.landmark}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Form.Label htmlFor="status_id">Status</Form.Label>
            <Form.Select
              as="select"
              id="status_id"
              name="status_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status_id}
              isInvalid={
                (formik.touched.status_id ?? false) &&
                !(formik.errors.status_id == null)
              }>
              <option disabled value="">
                --Select Status--
              </option>
              <option value={variables.ACTIVE_VALUE}>Active</option>
              <option value={variables.INACTIVE_VALUE}>Inactive</option>
            </Form.Select>
            {(formik.touched.status_id ?? false) && (
              <Form.Control.Feedback type="invalid">
                {formik.errors.status_id}
              </Form.Control.Feedback>
            )}
          </div>
        </Col>

        <Col lg={12} className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <Button
              role="addwarehouse"
              variant="primary"
              id="add-btn"
              type="submit">
              Submit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default WareHouseAddUser;
