import Dashboard from 'pages/Dashboard';

// Authentication

import Login from 'pages/Authentication/Login';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import ResetPassword from 'pages/Authentication/ResetPassword';
import Logout from 'pages/Authentication/Logout';
import ProductListing from 'pages/ProductListing';
import Coupons from 'pages/Coupons';
import Policy from 'pages/Policy';
import PolicyAdd from 'pages/Policy/PolicyAdd';
import WareHouseUserList from 'pages/WareHouse/WareHouseUserList';
import InvoiceList from 'pages/Invoice/InvoiceList';
import CreateInvoice from 'pages/Invoice/CreateInvoice';
import InvoiceDetails from 'pages/Invoice/InvoiceDetails';
import SupportListing from 'pages/Support/SupportListing';

import OrdersList from 'pages/Orders/OrderList';
import OrderDetails from 'pages/Orders/OrderDetails';

import NotifyMe from 'pages/NotifyMe';
import StockMangement from 'pages/StockManagement';

import ReviewsAndRatings from 'pages/Reviews & Ratings';
import Reviews from 'pages/Reviews & Ratings/Reviews';

import AreaSpecificSales from 'pages/Area-Sales';
import DiscountListing from 'Discounts/DiscountListing';
import PaymentTrackingList from 'pages/Payment_Tracking';
import Chat from 'pages/SupportChat/Chat';
import CouponAnalysis from 'pages/Coupons/Coupon-analysis';
import UserList from 'pages/UserList';
import Wishlist from 'pages/Wishlist';

const authProtectedRoutes = [
  {path: '/dashboard', component: Dashboard},
  {path: '/productlisting', component: ProductListing},
  {path: '/coupon', component: Coupons},
  {path: '/policy-list', component: Policy},
  {path: '/policy-add', component: PolicyAdd},
  {path: '/policy-update/:id', component: PolicyAdd},
  {path: '/support', component: SupportListing},
  {path: '/discount', component: DiscountListing},
  {path: '/warehouse-userlist', component: WareHouseUserList},
  {path: '/invoice-list', component: InvoiceList},
  {path: '/invoice-create', component: CreateInvoice},
  {path: '/order-invoice-detail/:id', component: InvoiceDetails},
  {path: '/order-list', component: OrdersList},
  {path: '/order-detail/:orderId', component: OrderDetails},
  {path: '/notified-products', component: NotifyMe},
  {path: '/stock-management', component: StockMangement},
  {path: '/reviews-ratings', component: ReviewsAndRatings},
  {path: '/reviews-ratings/:id', component: Reviews},
  {path: '/area-specific-sales', component: AreaSpecificSales},
  {path: '/payment-tracking', component: PaymentTrackingList},
  {path: '/support-chat/:chatId', component: Chat},
  {path: '/analysis', component: CouponAnalysis},
  {path: '/support-chat/:chatId', component: Chat},
  {path: '/user-list', component: UserList},
  {path: '/wishlist', component: Wishlist},
];

const publicRoutes = [
  // Authentication
  {path: '/login', component: Login},
  {path: '/logout', component: Logout},
  // {path: '/register', element: <Register />},
  {path: '/forgot-password', component: ForgotPassword},
  {path: '/reset-password/:token', component: ResetPassword},
];

export {authProtectedRoutes, publicRoutes};
