export const variables = {
  ecommerce_admin: 'ecommerce_admin',
  ACTIVE_VALUE: 1,
  INACTIVE_VALUE: 2,
  ADMIN_USERNAME: 'admin_username',

  DISCOUNT_ACTIVE_STATUS_ID: 35,
  DISCOUNT_ACTIVE_IN_STATUS_ID: 36,
  DISCOUNT_ACTIVE_STATUS: 'Active',
  DISCOUNT_INACTIVE_STATUS: 'In active',

  PRODUCT_ACTIVE_STATUS: 'Active',
  PRODUCT_INACTIVE_STATUS: 'Inactive',
  PRODUCT_ACTIVE_STATUS_ID: 9,
  PRODUCT_INACTIVE_STATUS_ID: 10,

  CATEGORY_ACTIVE_STATUS: 'Active',
  CATEGORY_INACTIVE_STATUS: 'In active',
  CATEGORY_ACTIVE_STATUS_ID: 3,

  GIFT_ACTIVE_STATUS: 'true',
  GIFT_INACTIVE_STATUS: 'false',
  GIFT_ACTIVE_STATUS_ID: 23,
  GIFT_INACTIVE_STATUS_ID: 24,

  OPTION_ACTIVE_STATUS: 'Active',
  OPTION_APPROVE_STATUS: 'Approve',
  OPTION_ACTIVE_STATUS_ID: 34,
  OPTION_APPROVE_STATUS_ID: 3,

  COUPON_ACTIVE_STATUS: 'Active',
  COUPON_INACTIVE_STATUS: 'In active',
  COUPON_ACTIVE_STATUS_ID: 28,

  DELIVERY_PENDING_STATUS: 'Pending',
  DELIVERY_ACCEPTED_STATUS: 'Accepted',
  DELIVERY_PENDING_STATUS_ID: 17,
  DELIVERY_ACCEPTED_STATUS_ID: 18,
};
export const regex = {
  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
};
export const menuItems: any = [
  {
    label: 'Menu',
    isHeader: true,
  },
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon: 'bi bi-speedometer2',
    link: '/dashboard',
    badgeName: 'Hot',
    badgeColor: 'danger',
  },
  // {
  //   id: 'products',
  //   label: 'Products',
  //   icon: 'bi bi-box-seam',
  //   link: '/#',
  //   click: function (e: any) {
  //     e.preventDefault();
  //     setIsEcommerce(!isEcommerce);
  //     setIscurrentState('Ecommerce');
  //     updateIconSidebar(e);
  //   },
  //   stateVariables: isEcommerce,
  //   subItems: [
  //     {
  //       id: 'listview',
  //       label: 'List View',
  //       link: '/productlisting',
  //       parentId: 'products',
  //     },
  //     {
  //       id: 'gridview',
  //       label: 'Grid View',
  //       link: '#',
  //       parentId: 'products',
  //     },
  //     {
  //       id: 'overview',
  //       label: 'Overview',
  //       link: '#',
  //       parentId: 'products',
  //     },
  //     {
  //       id: 'createproduct',
  //       label: 'Create Product',
  //       link: '#',
  //       parentId: 'products',
  //     },
  //     {
  //       id: 'categories',
  //       label: 'Categories',
  //       link: '#',
  //       parentId: 'products',
  //     },
  //   ],
  // },
  {
    id: 'warehouse',
    label: 'Ware House',
    icon: 'bi bi-person-bounding-box',
    link: '/warehouse-userlist',
  },
  {
    id: 'userlist',
    label: 'User List',
    icon: ' ph-user-circle-thin',
    link: '/user-list',
  },
  {
    id: 'order',
    label: 'Orders',
    icon: 'bi bi-cart4',
    link: '/order-list',
  },
  // {
  //   id: 'invoice',
  //   label: 'Invoice',
  //   icon: 'bi bi-archive',
  //   link: '/invoice-list',
  // },

  {
    id: 'coupons',
    label: 'Coupon',
    icon: 'bi bi-tag',
    link: '/coupons',
    subItems: [
      {
        id: 'listview',
        label: 'Coupons',
        link: '/coupon',
        parentId: 'coupons',
      },
      {
        id: 'analysis',
        label: 'Analysis',
        link: '/coupon-analysis',
        parentId: 'coupons',
      },
    ],
  },
  {
    id: 'notifyme',
    label: 'Notified Products',
    icon: 'bi bi-bell',
    link: '/notified-products',
  },
  {
    id: 'policy',
    label: 'Policy Mangement',
    icon: 'bi bi-tag',
    link: '/policy-list',
  },
  {
    id: 'reviewsAndRatings',
    label: 'Reviews & Ratings',
    icon: 'bi bi-star',
    link: '/reviews-ratings',
  },
  {
    id: 'wishlist',
    label: 'Wishlist',
    icon: 'bi bi-heart',
    link: '/wishlist',
  },
  {
    id: 'areaSpecificSales',
    label: 'Area Specific Sales',
    icon: 'bi bi-tag',
    link: '/area-specific-sales',
  },
  {
    id: 'stockmanagement',
    label: 'Stock Management',
    icon: 'bi bi-box',
    link: '/stock-management',
  },
  {
    id: 'paymenttracking',
    label: 'Payment Tracking',
    icon: 'bi bi-currency-dollar',
    link: '/payment-tracking',
  },
  {
    id: 'Support',
    label: 'Support',
    icon: 'bi bi-tag',
    link: '/support',
  },
  {
    id: 'Discounts',
    label: 'Price Up & Down',
    icon: 'bi bi-tag',
    link: '/discount',
  },
];
