import React from 'react';

const CancelledInvoiceSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="400"
      height="250"
      preserveAspectRatio="none"
      viewBox="0 0 400 250">
      <g mask='url("#SvgjsMask1560")' fill="none">
        <path
          d="M306 65L446 -75"
          strokeWidth="8"
          stroke="url(#SvgjsLinearGradient1558)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M399 2L315 86"
          strokeWidth="10"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M83 77L256 -96"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M281 212L460 33"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M257 62L76 243"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M305 123L214 214"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1558)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M327 222L440 109"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1558)"
          strokeLinecap="round"
          className="BottomLeft"></path>
        <path
          d="M287 109L362 34"
          strokeWidth="10"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M259 194L332 121"
          strokeWidth="8"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M376 186L240 322"
          strokeWidth="8"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M308 153L123 338"
          strokeWidth="6"
          stroke="url(#SvgjsLinearGradient1559)"
          strokeLinecap="round"
          className="TopRight"></path>
        <path
          d="M218 62L285 -5"
          strokeWidth="8"
          stroke="url(#SvgjsLinearGradient1558)"
          strokeLinecap="round"
          className="BottomLeft"></path>
      </g>
      <defs>
        <mask id="SvgjsMask1560">
          <rect width="400" height="250" fill="#ffffff"></rect>
        </mask>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="SvgjsLinearGradient1558">
          <stop stopColor="rgba(var(--tb-danger-rgb), 0)" offset="0"></stop>
          <stop stopColor="rgba(var(--tb-danger-rgb), 0.1)" offset="1"></stop>
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="100%"
          x2="100%"
          y2="0%"
          id="SvgjsLinearGradient1559">
          <stop stopColor="rgba(var(--tb-danger-rgb), 0)" offset="0"></stop>
          <stop stopColor="rgba(var(--tb-danger-rgb), 0.1)" offset="1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CancelledInvoiceSvg;
