import React from 'react';
import ModalContainer from 'Common/ModalContainer';
import {Form, Button, Col} from 'react-bootstrap';

interface Props {
  toogleComment: any;
  showModal: any;
}

const StatusModal = ({showModal, toogleComment}: Props): JSX.Element => {
  const modalData = (): JSX.Element => {
    return (
      <Col lg={12}>
        <div className="mb-3">
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Write your comment</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>
        </div>
        <div className="hstack gap-2 justify-content-end">
          <Button onClick={toogleComment} variant="primary" id="add-btn">
            Add Comment
          </Button>
        </div>
      </Col>
    );
  };

  return (
    <ModalContainer
      showModal={showModal}
      handleClose={toogleComment}
      modalTitle="Comment"
      modalBody={modalData()}
    />
  );
};

export default StatusModal;
