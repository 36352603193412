import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';

interface BreadCrumbProps {
  title: string;
  pageTitle: string;
  pageLink?: string;
}
const BreadCrumb = ({
  title,
  pageTitle,
  pageLink,
}: BreadCrumbProps): React.JSX.Element => {
  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex fs-4 align-items-center justify-content-between">
          <h4 role="title" className="mb-sm-0">
            {title}
          </h4>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={pageLink ?? '#'}>{pageTitle}</Link>
              </li>
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BreadCrumb;
