import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import SimpleBar from 'simplebar-react';
// import logo

// Import Components
import VerticalLayout from './VerticalLayouts/index';
import TwoColumnLayout from './TwoColumnLayout';
import {Button, Container} from 'react-bootstrap';
import HorizontalLayout from './HorizontalLayout';
interface VerticalLayoutProps {
  layoutType: string; // Adjust the type based on your needs
}
const Sidebar = (props: VerticalLayoutProps & any): React.JSX.Element => {
  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName('vertical-overlay');
    if (verticalOverlay != null) {
      verticalOverlay[0].addEventListener('click', function () {
        document.body.classList.remove('vertical-sidebar-enable');
      });
    }
  });

  const addEventListenerOnSmHoverMenu = (): void => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover'
    ) {
      document.documentElement.setAttribute(
        'data-sidebar-size',
        'sm-hover-active',
      );
    } else if (
      document.documentElement.getAttribute('data-sidebar-size') ===
      'sm-hover-active'
    ) {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    } else {
      document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
    }
  };

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm" data-testid="sidebarAdmin">
              ADMIN
            </span>
            <span className="logo-lg">ADMIN</span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">ADMIN</span>
            <span className="logo-lg">ADMIN</span>
          </Link>
          <Button
            variant="link"
            size="sm"
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover">
            <i className="ri-record-circle-line"></i>
          </Button>
        </div>
        {props.layoutType === 'horizontal' ? (
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </div>
        ) : props.layoutType === 'twocolumn' ? (
          <React.Fragment>
            <TwoColumnLayout />
            <div className="sidebar-background"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar id="scrollbar" className="h-100">
              <Container fluid>
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout />
                </ul>
              </Container>
            </SimpleBar>
            <div className="sidebar-background"></div>
          </React.Fragment>
        )}
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
