// export default instance;
import axios, {type AxiosError} from 'axios';
import Cookies from 'js-cookie';
import {BASE_URL} from 'utils/AppConfig';
import {variables} from 'utils/constant';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  async (config: any) => {
    if (config?.url === '/api/admin/store-support-chat') {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    const authDetails = Cookies.get(variables.ecommerce_admin);

    if (authDetails != null) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authDetails}`,
      };
    }
    const loader: HTMLElement | null = document.getElementById('cover-spin');
    if (loader !== null) {
      loader.style.display = 'block';
    }
    return config;
  },
  async (error: AxiosError) => {
    // Do something with request error
    return await Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    const loader: HTMLElement | null = document.getElementById('cover-spin');
    if (loader !== null) {
      loader.style.display = 'none';
    }
    // Any status code that lies within the range of 2xx causes this function to trigger
    // Do something with response data
    return response;
  },
  async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      Cookies.remove(variables.ecommerce_admin);
      window.location.href = '/';
    }
    const loader: HTMLElement | null = document.getElementById('cover-spin');
    if (loader !== null) {
      loader.style.display = 'none';
    }
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    // Do something with response error
    return await Promise.reject(error);
  },
);

export default instance;
