import React from 'react';
import {Card, Col, Container, Row} from 'react-bootstrap';
import CountUp from 'react-countup';
import InvoiceListTable from './InvoiceListTable';
import {DOCUMENT_TITLE} from 'Common/constants/layout';
import InvoiceSentSvg from 'Common/SVG/InvoiceSentSvg';
import PaidInvoiceSvg from 'Common/SVG/PaidInvoiceSvg';
import UnpaidInvoiceSvg from 'Common/SVG/UnpaidInvoiceSvg';
import CancelledInvoiceSvg from 'Common/SVG/CancelledInvoiceSvg';

const InvoiceList = (): JSX.Element => {
  document.title = DOCUMENT_TITLE.INVOICE_LIST;
  return (
    <div className="page-content">
      <Container fluid={true}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0">Invoice List</h4>
        </div>
        <Row>
          <Col xl={3} md={6}>
            <Card className="card-animate bg-info-subtle border-0 overflow-hidden">
              <div className="position-absolute end-0 start-0 top-0 z-0">
                <InvoiceSentSvg />
              </div>
              <Card.Body className="position-relative">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p className="text-uppercase fs-14 fw-medium text-muted mb-0">
                      Invoices Sent
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <h5 className="text-success fs-14 mb-0">
                      <i className="ri-arrow-right-up-line fs-13 align-middle"></i>{' '}
                      +89.24 %
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-24 fw-semibold mb-4">
                      <CountUp
                        end={559.25}
                        decimals={2}
                        prefix="$"
                        suffix="k"
                      />
                    </h4>
                    <span className="badge bg-info me-1">6,258</span>{' '}
                    <span className="text-muted align-bottom">
                      Invoices sent
                    </span>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-white text-primary rounded fs-3">
                      <i className="ph-file-text"></i>
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={3} md={6}>
            <Card className="card-animate bg-success-subtle border-0 overflow-hidden">
              <div className="position-absolute end-0 start-0 top-0 z-0">
                <PaidInvoiceSvg />
              </div>
              <Card.Body className="position-relative">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p className="text-uppercase fs-14 fw-medium text-muted mb-0">
                      Paid Invoices
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <h5 className="text-success fs-14 mb-0">
                      <i className="ri-arrow-right-up-line fs-13 align-middle"></i>{' '}
                      +8.09 %
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-24 fw-semibold mb-4">
                      <CountUp
                        end={409.66}
                        decimals={2}
                        prefix="$"
                        suffix="k"
                      />
                    </h4>
                    <span className="badge bg-info me-1">2,451</span>{' '}
                    <span className="text-muted">Paid by clients</span>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-white text-success rounded fs-3">
                      <i className="ph-check-square-offset"></i>
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={3} md={6}>
            <Card className="card-animate bg-warning-subtle border-0 overflow-hidden">
              <div className="position-absolute end-0 start-0 top-0 z-0">
                <UnpaidInvoiceSvg />
              </div>
              <Card.Body className="position-relative">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p className="text-uppercase fs-14 fw-medium text-muted mb-0">
                      Unpaid Invoices
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <h5 className="text-danger fs-14 mb-0">
                      <i className="ri-arrow-right-down-line fs-13 align-middle"></i>{' '}
                      +9.01 %
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-24 fw-semibold mb-4">
                      <CountUp
                        end={136.98}
                        decimals={2}
                        prefix="$"
                        suffix="k"
                      />
                    </h4>
                    <span className="badge bg-info me-1">720</span>{' '}
                    <span className="text-muted">Unpaid by clients</span>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-white text-warning rounded fs-3">
                      <i className="ph-clock"></i>
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col xl={3} md={6}>
            <Card className="card-animate bg-danger-subtle border-0 overflow-hidden">
              <div className="position-absolute end-0 start-0 top-0 z-0">
                <CancelledInvoiceSvg />
              </div>
              <Card.Body className="position-relative">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <p className="text-uppercase fs-14 fw-medium text-muted mb-0">
                      Cancelled Invoices
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <h5 className="text-success fs-14 mb-0">
                      <i className="ri-arrow-right-up-line fs-13 align-middle"></i>{' '}
                      +7.55 %
                    </h5>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-24 fw-semibold mb-4">
                      <CountUp end={84.2} decimals={1} prefix="$" suffix="k" />
                    </h4>
                    <span className="badge bg-info me-1">502</span>{' '}
                    <span className="text-muted">Cancelled by clients</span>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-white text-danger rounded fs-3">
                      <i className="ph-trash"></i>
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <InvoiceListTable />
      </Container>
    </div>
  );
};

export default InvoiceList;
