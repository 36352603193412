// Include Both Helper File with needed methods
import {getFirebaseBackend} from '../../../helpers/firebase_helper';
import {
  postFakeRegister,
  postJwtRegister,
} from '../../../helpers/fakebackend_helper';

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange,
} from './reducer';

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
export const registerUser = (user: any) => async (dispatch: any) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      response = fireBaseBackend.registerUser(user.email, user.password);
      // yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      response = postJwtRegister('/post-jwt-register', user);
      // yield put(registerUserSuccessful(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH != null) {
      response = postFakeRegister(user);
      const data: any = await response;

      // if (data.message === "success") {
      dispatch(registerUserSuccessful(data));
      // } else {
      //   dispatch(registerUserFailed(data));
      // }
    }
  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = (): any => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = (): any => {
  try {
    const response = apiErrorChange('');
    return response;
  } catch (error) {
    return error;
  }
};
