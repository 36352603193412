/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, {useEffect, useState} from 'react';
import {Container, Card, Row, Col, Button, Pagination} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import ApiUtils from 'api/ApiUtils';
import StarRating from 'Common/StarRating';
import DefaultImgPlaceholder from 'assets/images/default-placeholder.jpg';
import {toast} from 'react-toastify';

const ReviewsAndRatings: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const defaultPage = 1;
  const [products, setProducts] = useState([]);
  const [pageValue, setPageValue] = useState(defaultPage);
  // const [dateFilter, setDateFilter] = useState({
  //   startDate: '',
  //   endDate: '',
  // });
  const [totalRecords, setTotalRecords] = useState(defaultPage);
  const [searchTerm, setSearchTerm] = useState('');

  const currentData = Math.ceil(totalRecords / 10);

  const fetchProducts: any = async (): Promise<void> => {
    try {
      const response: any = await ApiUtils.getAllProduct(
        `page=${pageValue}&page_size=12&sort_direction=asc&search=${searchTerm}`,
      );
      const data = response.data;
      setProducts(data.data);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleViewReviews = (productId: number, ratings: any): void => {
    if (ratings !== null) {
      navigate(`/reviews-ratings/${productId}`);
    } else {
      toast.error('No Reviews');
    }
  };

  const handleNextPagination = (pageSize: any): void => {
    if (currentData > 0 && currentData > pageSize) {
      setPageValue(pageSize + 1);
    }
  };
  const handlePrevPagination = (pageSize: any): void => {
    if (pageSize > 1 && currentData >= pageSize) {
      setPageValue(pageSize - 1);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [pageValue, searchTerm]); /* dateFilter */

  // const formatDate = (date: any): string => {
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //   const day = date.getDate().toString().padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  // const handleDateFunction = (e: any): void => {
  //   const inputStartDate = new Date(e[0]);
  //   const formattedStartDate = formatDate(inputStartDate);

  //   const inputEndDate = new Date(e[1]);
  //   const formattedEndDate = formatDate(inputEndDate);

  //   setDateFilter(v => ({
  //     ...v,
  //     startDate: formattedStartDate,
  //     endDate: formattedEndDate,
  //   }));
  // };

  const truncateText = (text: string, maxLength: number): any => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <div className="page-content" data-testid="reviewAndRating">
      <Container fluid>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0">Reviews & Ratings</h4>
          <div className="d-flex align-items-center gap-2">
            {/* <Flatpickr
              className="form-control flatpickr-input w-100"
              placeholder="Select Date"
              onChange={handleDateFunction}
              options={{
                mode: 'range',
                dateFormat: 'd M, Y',
                maxDate: new Date(),
              }}
            /> */}
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
              }}
              className="form-control mr-3"
              style={{maxWidth: '300px'}}
            />
          </div>
        </div>
        <Row>
          {products && products.length > 0 ? (
            <>
              {products
                ?.filter((data: any) => {
                  return data.rating > 0;
                })
                .map((product: any) => (
                  <Col md={6} lg={4} key={product?.id}>
                    <Card className="mb-3 card-animate">
                      <Card.Body>
                        <div className="d-flex justify-content-between gap-3">
                          <Card.Img
                            src={
                              product.product_option_value_image !== null
                                ? product.product_option_value_image?.image
                                : DefaultImgPlaceholder
                            }
                            title={product.name}
                            className="avatar-sm rounded"
                            onError={({currentTarget}) => {
                              currentTarget.onerror = null;

                              currentTarget.src = DefaultImgPlaceholder;
                            }}
                          />
                          <p
                            style={{height: '60px', maxWidth: '260px'}}
                            className="text-uppercase mb-3 fw-medium text-muted fs-14">
                            {truncateText(product.name, 50)}
                          </p>
                        </div>
                        <div className="mt-3 d-flex flex-column align-items-center">
                          <h5>
                            {product?.rating ?? 0}/
                            <span style={{color: '#888'}}>5</span>
                          </h5>
                          <StarRating
                            rating={product?.rating ?? 0}
                            totalStars={5}
                          />
                        </div>
                      </Card.Body>
                      <Button
                        role="viewAllReview"
                        onClick={() => {
                          handleViewReviews(product.id, product?.rating);
                        }}
                        className="btn btn-primary btn-sm">
                        View All Reviews
                      </Button>
                    </Card>
                  </Col>
                ))}
            </>
          ) : (
            <>
              <h2>No Data Found</h2>
            </>
          )}
        </Row>
        <Row className="justify-content-end mt-3">
          <div className="d-flex justify-content-end">
            <Pagination>
              <Pagination.Prev
                onClick={() => {
                  handlePrevPagination(pageValue);
                }}
                disabled={pageValue === 1}
              />
              <Pagination.Item active>{pageValue}</Pagination.Item>
              <Pagination.Next
                onClick={() => {
                  handleNextPagination(pageValue);
                }}
                disabled={pageValue === currentData || products.length === 0}
              />
            </Pagination>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ReviewsAndRatings;
